<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-25 10:41:43
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('supplier_partner.475422@partner')">
      <span class="fa fa-handshake-o fa-2x"></span>
    </page-top>
    <el-card>
      <section style="display:flex;justify-content:flex-end;">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          class="table-btn btn-gray"
          :perm="8790"
          @click="add"
        ></KtButton>
      </section>
      <el-table
        :data="tableData"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
        :header-cell-style="{'text-align':'center',}"
        :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="partner_id" label="ID"></el-table-column>
        <el-table-column prop="company_name" :label="$t('aboutus.E8221A@restaurant_name')"></el-table-column>
        <el-table-column prop="pay_method" :label="$t('supplier_partner.249A92@payment_method')"></el-table-column>
        <el-table-column prop="overdue" :label="$t('supplier_partner.AADE98@Late_payment_day')"></el-table-column>
        <el-table-column
          prop="settle_date"
          :label="$t('supplier_partner.A73300@Designated_monthly_statement_day')"
        ></el-table-column>
        <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="250">
          <template slot-scope="scope">
            <KtButton
              :label="$t('aboutus.0B6945@Cooperation_Products')"
              type="info"
              :perm="15813265235565"
              @click="getPartnerGoods(scope.row.partner_id)"
            ></KtButton>
            <KtButton
              :label="$t('public.B6D57F@btn_modify')"
              type="info"
              :perm="6351"
              @click="modify(scope.row)"
            ></KtButton>
            <KtButton
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              :perm="690"
              @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
      :title="addModify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
      width="45%"
      :visible.sync="dialogVisible"
    >
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form :model="dialogForm" ref="form" :rules="rules" label-width="150px">
            <el-form-item
              :label="$t('supplier_partner.C6DED8@partner_ID')"
              prop="partner_id"
              v-if="addModify"
            >
              <!-- <el-input style="width:200px" v-model="dialogForm.partner_id" disabled></el-input> -->
              <span class="ml10">{{dialogForm.partner_id}}</span>
            </el-form-item>
            <el-form-item :label="$t('shop_cart.4C8583@name')" prop="restaurant_id">
              <el-select v-model="dialogForm.restaurant_id" filterable>
                <el-option
                  v-for="item in Selector"
                  :key="item.company_id"
                  :value="item.company_id"
                  :label="item.company_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('supplier_partner.249A92@payment_method')" prop="pay_method">
              <el-select v-model="dialogForm.pay_method">
                <el-option
                  v-for="item in payMethod"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dialogForm.pay_method==2||dialogForm.pay_method==3"
              :label="$t('supplier_partner.AADE98@Late_payment_day')"
              prop="overdue"
            >
              <el-input
                v-model="dialogForm.overdue"
                type="number"
                @input.native="$inputNumber"
                style="width:180px"
              ></el-input>
              {{$t('aboutus.D4D5D3@day')}}
            </el-form-item>
            <el-form-item
              v-if="dialogForm.pay_method==3"
              :label="$t('restaurant_supplier_addsuppluer.610635@specify_date')"
              prop="settle_date"
            >
              <!-- 1-31 -->
              <el-select
                v-model="dialogForm.settle_date"
                :placeholder="$t('placeholder.F0DDC0@select')"
              >
                <el-option
                  v-for="item in 31"
                  :key="item"
                  :label="item+$t('aboutus.CAF889@day')"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="comfirmDialog"
        >{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
    </el-dialog>

    <el-dialog title :visible.sync="goodVisible" width="80%">
      <section style="display:flex;justify-content:flex-end;">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          class="table-btn btn-gray"
          style="height:35px; float: right;"
          :perm="15813265741821"
          @click="addGood"
        ></KtButton>
      </section>
      <el-table
        :data="goodData"
        v-loading="goodLoading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
        :header-cell-style="{'text-align':'center',}"
        :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="partner_gid" :label="$t('aboutus.F2C0FA@Partner_Product_ID')"></el-table-column>
        <el-table-column
          prop="goods_name"
          :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')"
        ></el-table-column>
        <el-table-column prop="sku_name" :label="$t('restaurant_inventory.198560@sku_name')"></el-table-column>
        <el-table-column prop="original_price" :label="$t('aboutus.111BDD@Original_Price')"></el-table-column>
        <el-table-column prop="price" :label="$t('aboutus.932932@special_price')">
          <template scope="scope">
            <div v-if="control[scope.$index]">
              <el-input
                size="small"
                type="number"
                @input.native="$inputLenght"
                v-model="scope.row.price"
              ></el-input>
            </div>
            <span v-else>{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="valid"
          :label="$t('supplier-produceManage-publishProduct.92C10D@Shelf')"
        >
          <template scope="scope">
            <span v-if="scope.row.valid">{{$t('aboutus.980E0D@yes')}}</span>
            <span v-else>{{$t('aboutus.A03514@no')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('aboutus.225A90@created_time')"></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="210">
          <template scope="scope">
            <!-- 修改/保存 -->
            <ktButton
              type="info"
              :perm="15813267036133"
              size="small"
              :label="control[scope.$index]?$t('public.1A3EBC@save'):$t('public.B6D57F@btn_modify')"
              @click="updata(scope.$index,control[scope.$index],scope.row)"
            ></ktButton>
            <!-- 取消 -->
            <ktButton
              v-if="control[scope.$index]"
              type="warning"
              :perm="15813267036133"
              size="small"
              :label="$t('public.836FD4@cancel')"
              @click="cancel(scope.$index)"
            ></ktButton>
            <KtButton
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              :perm="15813267954600"
              @click="deleteGood(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChangeGood"
          :page-sizes="[5,10,15]"
          :page-size="pageSizeGood"
          @current-change="handleCurrentChangeGood"
          :current-page="currentPageGood"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalGood"
        ></el-pagination>
      </section>
      <span slot="footer">
        <el-button size="small" @click=" goodVisible= false">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="goodVisible= false"
        >{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
      <el-dialog
        :title="$t('public.553159@tips')"
        :visible.sync="addVisible"
        @close="closeGoodsDialog"
        top="8vh"
        width="45%"
        center
        append-to-body
      >
        <span slot="title" class="dialog-title">{{$t('restaurant_inventory.0009CF@product_name')}}</span>
        <el-row>
          <el-col :span="18" :offset="3">
            <div class="category" style="padding-bottom:20px;">
              <section>
                <div class="title mt10">{{ $t('restaurant_buy_B2BProduct.6C52C8@product_name') }}</div>
                <el-select
                  filterable
                  v-model="gid"
                  class="mt10"
                  style="width:100%"
                  clearable
                  :placeholder="$t('aboutus.900298@Please_Enter_a_Product_Name_or_Keyword')"
                  @change="goodsChange"
                  :loading="loading"
                  size="small"
                >
                  <el-option
                    v-for="item in supplierGoods"
                    :key="item.gid"
                    :label="item.name"
                    :value="item.gid"
                  ></el-option>
                </el-select>
              </section>
              <el-form
                v-if="goodsData.gid"
                :model="goodsData"
                ref="form1"
                :rules="priceRule"
                label-width="120px"
                style="margin-top: 20px"
              >
                <el-form-item
                  :label="$t('restaurant_inventory.0009CF@product_name')"
                >:&nbsp;{{goodsData.name}}</el-form-item>
                <el-form-item
                  :label="$t('restaurant_supplier_addSupProduct.16EBEC@price')"
                >:&nbsp;{{goodsData.price}}</el-form-item>
                <el-form-item
                  :label="v.spac_name"
                  :prop="v.spac_name"
                  v-for="(v,i) in goodsData.sku_selector"
                  :key="i"
                >
                  :&nbsp;
                  <el-radio-group v-model="radioArr[i]">
                    <el-radio-button
                      :label="c.value_id"
                      v-for="(c,j) in v.spac_value"
                      :key="j"
                    >{{c.value_name}}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  :label="$t('aboutus.72F5FF@Minimum_order_quantity')"
                >:&nbsp;{{goodsData.min_quantity}}</el-form-item>
                <el-form-item
                  v-show="!goodsData.sku_selector||goodsData.sku_selector.length==0"
                  :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')"
                >:&nbsp;{{goodsData.unit}}</el-form-item>
                <el-form-item
                  v-show="goodsData.unit_num&&goodsData.unit_name"
                  :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')"
                >:&nbsp;{{goodsData.unit_num}}/{{goodsData.unit_name}}</el-form-item>
                <el-form-item prop="price" :label="$t('aboutus.932932@special_price')">
                  <section class="flex-cc">
                    :&nbsp;
                    <el-input
                      @input.native="$inputLenght"
                      type="number"
                      v-model="goodsform.price"
                      :placeholder="$t('placeholder.D20BE9@Please_input')"
                    ></el-input>
                  </section>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
          <b-btn
            class="table-btn btn-yellow"
            @click="addGoodsGroupItem"
          >{{ $t('public.3B45A2@confirm')}}</b-btn>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";

export default {
  data() {
    return {
      tableData: [],
      goodData: [],
      goodVisible: false,
      addVisible: false,
      currentPage: 1,
      pageSize: 10,
      currentPageGood: 1,
      pageSizeGood: 10,
      supplier: "",
      input: "",
      total: 0,
      totalGood: 0,
      dialogForm: {
        partner_id: "",
        restaurant_id: "",
        pay_method: "",
        overdue: "",
        settle_date: ""
      },
      rules: {
        restaurant_id: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        pay_method: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        }
      },
      addModify: 0,
      Selector: [],
      supplierGoods: [],
      dialogVisible: false,
      payMethod: [
        {
          value: 1,
          label: this.$t("aboutus.7F2D75@Cash_on_delivery")
        },
        {
          value: 2,
          label: this.$t("aboutus.782FFE@Deferred_payment")
        },
        {
          value: 3,
          label: this.$t("aboutus.D0F1D6@Set_statement")
        }
      ],
      gid: "",
      radioArr: [],
      goodsData: {},
      goodsform: {
        gid: "",
        sku_id: "",
        price: 0
      },
      priceRule: {
        price: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },

      loading: false,
      goodLoading: false,
      partner_id: "",
      control: [],
      oldTableData: []
    };
  },
  components: {
    pageTop,
    KtButton
  },
  watch: {
    radioArr: {
      handler: function(radioArr) {
        if (
          !Boolean(
            this.goodsData.sku_selector &&
              this.goodsData.sku_selector.length > 0
          )
        )
          return;
        let flag = true;
        radioArr.forEach((v, i) => {
          if (!radioArr[i]) {
            flag = false;
          }
        });
        if (flag) {
          this.getStock();
        }
      },
      deep: true
    }
  },
  created() {
    this.getTableData();
    this.restaurantSelector();
  },
  methods: {
    // 获取合作产品
    getPartnerGoods(partner_id) {
      this.goodData = [];
      this.totalGood = 0;
      this.partner_id = partner_id;
      this.goodVisible = true;
      this.$http
        .getPartnerGoods({
          partner_id,
          size: this.pageSizeGood,
          page: this.currentPageGood
        })
        .then(res => {
          this.goodData = res.data.list;
          this.goodLoading = false;
          this.totalGood = res.data.sum;
          this.oldTableData = JSON.parse(JSON.stringify(res.data.list));
          this.goodData.forEach((v, i) => {
            this.$set(this.control, i, false);
          });
        });
    },
    // 获取列表数据
    getTableData() {
      this.loading = true;
      this.$http
        .getPartnerList({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.sum;
        });
    },
    // 修改/保存
    updata(i, v, row) {
      console.log(row);
      if (v) {
        // 点击保存发请求
        this.$confirm(
          this.$t("aboutus.BAABFA@Confirm_the_modification"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
        ).then(() => {
          this.goodLoading = true;
          const { partner_gid, price } = row;
          this.$http
            .updatePartnerGoods({
              partner_gid,
              price
            })
            .then(res => {
              this.$set(this.control, i, false);
              this.getPartnerGoods(this.partner_id);
            });
        });
      } else {
        // 点击修改变输入框
        this.$set(this.control, i, true);
      }
    },
    // 取消
    cancel(i) {
      this.$set(this.control, i, false);
      this.$set(
        this.goodData,
        i,
        JSON.parse(JSON.stringify(this.oldTableData[i]))
      );
    },
    comfirmDialog() {
      let params = Object.assign({}, this.dialogForm);
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.addModify) {
            // 修改
            this.$http.updatePartner(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            // 新增
            this.$http.addPartner(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });
    },

    // 修改时获取信息
    modify(row) {
      this.addModify = 1;
      this.row = row;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      this.$http
        .getPartnerInfo({
          partner_id: row.partner_id
        })
        .then(res => {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.dialogForm = res.data;
          });
        });
    },
    // 打开新增弹框
    add() {
      this.addModify = 0;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    // 新增合作产品
    addGood() {
      this.addVisible = true;
      this.supplierGoodsSelector();
    },
    // 刪除
    deleteUser(row) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delPartner({
            partner_id: row.partner_id
          })
          .then(res => {
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getTableData();
          });
      });
    },
    // 刪除产品
    deleteGood(row) {
      console.log(row);

      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delPartnerGoods({
            partner_gid: row.partner_gid
          })
          .then(res => {
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getPartnerGoods(this.partner_id);
          });
      });
    },
    // 選擇器
    restaurantSelector() {
      this.$http.restaurantSelector().then(res => {
        this.Selector = res.data;
      });
    },
    // 供应商产品选择器
    supplierGoodsSelector() {
      this.$http.supplierGoodsSelector().then(res => {
        this.supplierGoods = res.data;
      });
    },
    // 选中产品
    goodsChange() {
      this.goodsData = {};
      this.supplierGoods.forEach((v, i) => {
        if (v.gid == this.gid) {
          this.goodsData = v;
        }
      });
      this.radioArr = [];
      this.goodsform.gid = this.goodsData.gid;
      this.goodsData.sku_selector.forEach((v, i) => {
        this.$set(this.radioArr, i, "");
      });
    },
    //获取库存
    getStock() {
      let params = {
        gid: this.goodsform.gid,
        value_json: JSON.stringify(this.radioArr)
      };
      this.$http.getB2BGoodsAttr(params).then(res => {
        console.log(res);
        this.goodsData = Object.assign(this.goodsData, res.data);
        this.goodsform.sku_id = res.data.sku_id;
      });
    },
    // 添加产品
    addGoodsGroupItem() {
      this.$refs["form1"].validate(valid => {
        if (valid) {
          // if (!Boolean(this.goodsform.price)) {
          //   this.$message.warning(this.$t('shop-goods.5E8023@Please_complete_the_form'))
          //   return
          // }
          this.$http
            .addPartnerGoods({
              partner_id: this.partner_id,
              ...this.goodsform
            })
            .then(res => {
              this.addVisible = false;
              this.getPartnerGoods(this.partner_id);
            });
        } else {
          return false;
        }
      });
    },
    // 关闭内嵌弹框
    closeGoodsDialog() {
      this.radioArr = [];
      this.goodsform = {
        gid: "",
        sku_id: "",
        price: ""
      };
      this.gid = "";
      this.goodsData = {};
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChangeGood(val) {
      this.pageSizeGood = val;
      this.getPartnerGoods();
    },
    handleCurrentChangeGood(val) {
      this.currentPageGood = val;
      this.getPartnerGoods();
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}

.tabs {
  width: 170px;
  margin-bottom: -20px;

  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}

.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}
</style>
